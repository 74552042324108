<template>
    <mercur-dialog :is-open.sync="dialogActiveLocal">
        <div slot="header"><h3>Download shipping labels</h3></div>
        <div slot="default">
            <p>Please enter the number of shipping labels you would like to receive</p>
            <mercur-input
                v-model.number="numberOfShippingLabels"
                data-test="GenerateShippingLabelsDialogNumberOfShippingLabelsInput"
                type="number"
                min="1"
                step="1">
                Number of shipping labels
            </mercur-input>

            <div v-if="canShowPackageDetails && numberOfShippingLabels > 1">
                <p :class="{'error' : $v.$error}">Please make sure to enter the correct quantities so they add up to <strong>{{totalQuantity}}</strong>. Currently you have entered: <strong>{{totalInputQuantity}}</strong></p>
                <fieldset class="packages">
                    <legend>Packages</legend>
                    <transition name="fade">
                        <mercur-button
                            v-if="!isInputEqualToAutofill"
                            class="btn btn-icon btn-yellow packages__autofill"
                            @click="fillInAutoFillValues()"
                        ><i class="fas fa-magic"></i></mercur-button>
                    </transition>
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <td class="packages-header">#</td>
                                <td class="text-secondary">Weight (kg)</td>
                                <td class="text-secondary">Quantity</td>
                            </tr>
                        </thead>
                        <tr v-for="(shippingLabelDetail, key) in shippingLabelDetails" :key="key">
                            <td class="packages__cell">{{key + 1}}</td>
                            <td>
                                <mercur-input
                                    v-model.number="shippingLabelDetail.weight"
                                    :data-test="'GenerateShippingLabelsDialogShippingLabelsWeightInput'+key"
                                    min="0.01"
                                    type="number"
                                    class="mb-0"
                                    @input="$v.$touch"></mercur-input>
                            </td>
                            <td>
                                <mercur-input
                                    v-model.number="shippingLabelDetail.quantity"
                                    :data-test="'GenerateShippingLabelsDialogShippingLabelsQttInput'+key"
                                    min="1"
                                    class="mb-0"
                                    type="number"></mercur-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="packages__cell text-secondary">Total:</td>
                            <td class="pl-4">{{totalInputWeight}}</td>
                            <td class="pl-4">{{totalInputQuantity}}</td>
                        </tr>
                    </table>
                </fieldset>
            </div>
        </div>
        <div slot="footer">
            <mercur-button class="btn text-uppercase" @click="$emit('update:dialogActive', false)">Cancel</mercur-button>
            <mercur-button data-test="GenerateShippingLabelsDialogSaveBtn" class="btn btn-raised btn-yellow text-uppercase" :disabled="isLoading" @click="submit">Download</mercur-button>
        </div>
        <mercur-progress-bar v-if="isLoading" indeterminate></mercur-progress-bar>
    </mercur-dialog>
</template>
<script>
export default {
    name: 'GenerateShippingLabelsDialog',
    props: {
        dialogActive: {
            default: false,
        },
        isLoading: {
            default: false,
        },
        canShowPackageDetails: {
            default: false,
        },
        orderlines: {
            required: true,
        },
    },
    validations: {
        shippingLabelDetailsQuantityIsValid (value) {
            if (!this.canShowPackageDetails) {
                return true
            }
            return this.totalQuantity === this.totalInputQuantity
        },
    },
    data () {
        return {
            numberOfShippingLabels: 1,
            shippingLabelDetails: [],
        }
    },
    computed: {
        dialogActiveLocal: {
            get () {
                return this.dialogActive
            },
            set (value) {
                this.$emit('update:dialogActive', value)
            },
        },
        totalWeight () {
            if (!this.orderlines) {
                return 0
            }

            return this.orderlines.reduce((totalWeight, orderline) => {
                if (orderline.productWeight) {
                    totalWeight += orderline.productWeight
                }
                return totalWeight
            }, 0)
        },

        totalInputWeight () {
            if (this.shippingLabelDetails.length === 1) {
                return this.totalWeight
            }

            return this.shippingLabelDetails.reduce((totalInputWeight, labelInput) => {
                return totalInputWeight + labelInput.weight
            }, 0)
        },
        autoWeightPerPackage () {
            if (this.totalWeight === 0) {
                return 0
            }
            return this.totalWeight / this.numberOfShippingLabels
        },

        totalQuantity () {
            if (!this.orderlines) {
                return 0
            }

            return this.orderlines.reduce((totalQuantity, orderline) => {
                if (orderline.quantity) {
                    totalQuantity += orderline.quantity
                }
                return totalQuantity
            }, 0)
        },
        totalInputQuantity () {
            if (this.shippingLabelDetails.length === 1) {
                return this.totalQuantity
            }

            return this.shippingLabelDetails.reduce((totalInputQuantity, labelInput) => {
                return totalInputQuantity + labelInput.quantity
            }, 0)
        },
        autoQuantityPerPackage () {
            if (this.totalQuantity === 0) {
                return 0
            }
            return this.totalQuantity / this.numberOfShippingLabels
        },
        isInputEqualToAutofill () {
            return JSON.stringify(this.shippingLabelDetails) === JSON.stringify(this.getAutoFillValues())
        },
    },
    watch: {
        numberOfShippingLabels: {
            handler () {
                this.fillInAutoFillValues()
            },
            immediate: true,
        },
    },
    methods: {
        fillInAutoFillValues () {
            this.$set(this, 'shippingLabelDetails', this.getAutoFillValues())
        },
        getAutoFillValues () {
            return new Array(this.numberOfShippingLabels).fill(null).map(() => {
                return {
                    weight: JSON.parse(JSON.stringify(this.autoWeightPerPackage)),
                    quantity: JSON.parse(JSON.stringify(this.autoQuantityPerPackage)),
                }
            })
        },
        submit () {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$invalid) {
                return
            }
            const payload = {
                numberOfShippingLabels: this.numberOfShippingLabels,
            }

            if (this.numberOfShippingLabels > 1 && this.canShowPackageDetails) {
                payload.shippingLabelDetails = this.shippingLabelDetails
            }
            this.$emit('downloadShippingLabel', payload)
        },
    },
}
</script>

<style lang="scss" scoped>
    /deep/ .dialog__wrapper {
        width: 90vw;
        max-width: 800px;
    }
    .packages {
        position: relative;
        &__cell {
            vertical-align: middle;
            text-align: right;
        }
        &__autofill {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
</style>
