<template>
    <ul class="file-list">
        <li
            v-for="file in files"
            :key="file.objectName"
            :class="{'file-list__file--is-completed': file.completed}"
            class="file-list__file"
        >
            <mercur-button @click="deleteFile(file)" class="file-list__delete btn btn-icon">
                <i class="fas fa-trash"></i>
            </mercur-button>
            <span
                class="file-list__progress"
                :class="{'file-list__progress--is-completed': file.completed}"
                :style="`width: ${file.percentCompleted}%;`"
            >{{file.percentCompleted}}%&nbsp;</span>
            <span class="file-list__text">
                {{file.originalFilename}}
            </span>
        </li>
        <template v-if="files.length === 0">
            <em>No files uploaded</em>
        </template>
    </ul>
</template>
<script>
export default {
    name: 'FiledropperList',
    props: {
        value: {
            default: () => ([]),
        },
    },
    computed: {
        files: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        deleteFile (file) {
            const index = this.files.findIndex(({ objectName }) => objectName === file.objectName)
            this.files.splice(index, 1)
        },
        uploadUpdate (value) {
            if (value.type === 'start') {
                this.files.unshift(value.file)

                return
            }

            const fileReference = this.files.find(({ objectName }) => objectName === value.file.objectName)

            if (!fileReference) {
                return
            }

            this.$set(fileReference, 'percentCompleted', value.percentCompleted)

            if (value.type === 'completed') {
                this.$set(fileReference, 'completed', true)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.file-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    &__file {
        border-left-width: 3px;
        border-left-style: solid;
        border-left-color: transparent;
        padding-left: 3px;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 3px;
        margin-bottom: 3px;
        position: relative;
        background-color: #f7f7f7;

        &--is-completed {
            border-left-color: #70c36a;
        }
    }

    &__progress {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0;
        background-color: #eaf8ff;
        transition: opacity 200ms;
        overflow: hidden;
        font-size: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--is-completed {
            opacity: 0;
        }
    }

    &__text {
        position: relative;
        vertical-align: middle;
    }

    &__delete {
        vertical-align: middle;
    }
}
</style>
