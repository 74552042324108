<template>
    <mercur-dialog :is-open.sync="localDialogActive">
        <div slot="header" class="flex-1">
            <h3>Change details</h3>
            <div v-if="numberOfOrderLinesSelected === 1" class="flex-1">
                <mercur-input
                    data-test="updateOrderlineSkuInput"
                    :value="details.sku"
                    :readonly="true"
                    :clearable="false"
                    :disabled="true"
                >
                    SKU
                    <a slot="suffix" class="link" @click="isEditingAttribute = true" :disabled="!canEditSku"><i class="fas fa-edit"></i></a>
                </mercur-input>
            </div>
        </div>
        <div slot="default" class="full-height-layout fill">
            <mercur-dialog :is-open.sync="isEditingAttribute">
                <update-order-line-sku-dialog
                    v-if="numberOfOrderLinesSelected === 1"
                    :attributes="selectedOrderLines[0].attributes"
                    :selected-order-lines="selectedOrderLines"
                    @setAlternativeSKU="handleSkuChange"

                ></update-order-line-sku-dialog>
            </mercur-dialog>
            <div v-if="numberOfOrderLinesSelected" class="fill px-3">
                <template v-if="isLegacyOrders">
                    <pretty-select
                        v-if="allSuppliers"
                        data-test="updateOrderlineAllSuppliersSelect"
                        v-model="selectedLegacySupplier"
                        :options="allSuppliers"
                        label="supplierName"
                        placeholder="Supplier"
                    ></pretty-select>
                    <pretty-select
                        v-if="allServiceLevels"
                        v-model="selectedLegacyServiceLevel"
                        :options="allServiceLevels"
                        label="serviceLevelName"
                        placeholder="Service level"
                    ></pretty-select>
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col-6">
                            <pretty-select
                                v-if="suitableFacilities"
                                data-test="updateOrderlineAllFacilitySelect"
                                v-model="selectedFacility"
                                :options="suitableFacilities"
                                label="facilityName"
                                placeholder="Supplier Facility"
                                required
                                @input="onFacilityChanged"
                                :disabled="!canEditFacility"
                            ></pretty-select>
                        </div>
                        <div class="col-6">
                            <pretty-select
                                v-if="suitableServiceLevels"
                                v-model="selectedServiceLevel"
                                :options="suitableServiceLevels"
                                :get-option-label="getServiceLevelOptionLabel"
                                placeholder="Carrier Service Levels"
                                required
                                :disabled="!canEditServiceLevel"
                                @input="onServiceLevelChanged"
                                class="mb-3"
                            ></pretty-select>
                            <span v-if="!suitableFacilities || !suitableServiceLevels">
                                <mercur-spinner/>
                            </span>
                        </div>
                    </div>
                    <p v-if="suitableFacilities && suitableFacilities.length === 0">
                        There are no suitable facilities found for this order line.
                    </p>
                </template>
                <div class="row">
                    <div class="col-6">
                        <mercur-input
                            v-model="details.targetDispatchDate"
                            data-test="updateOrderlineAlltargetDispatchDateInput"
                            type="date"
                            @input="dirtyFields.push('targetDispatchDate')"
                        >
                            Target dispatch Date
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <pretty-select
                            v-model="details.orderLineStatus"
                            :options="getAvailableOrderLineStatusses()"
                            data-test="updateOrderlineStatusSelect"
                            label="title"
                            :reduce="status => status.value"
                            :disabled="isSupplierChanged"
                            placeholder="Order State"
                            ref="orderLineStatusSelect"
                            required
                            @input="dirtyFields.push('orderLineStatus')"
                        ></pretty-select>
                        <div v-if="isSupplierChanged" class="-mt-15 mb-15 message message--small message--warning">When changing the supplier or facility the order state needs to be set to <em>Ready for production</em></div>
                    </div>
                    <div class="col-6">
                        <mercur-input data-test="updateOrderlineProductSkuInput" v-model="details.producerSku" @input="dirtyFields.push('producerSku')">
                            Producer sku
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <mercur-input data-test="updateOrderlineProducerRefInput" v-model="details.producerReference" @input="dirtyFields.push('producerReference')">
                            <label>Producer reference</label>
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <mercur-input data-test="updateOrderlinePurchasePriceInput" v-model="details.productCost" @input="dirtyFields.push('productCost')">
                            Purchase Price (€)
                        </mercur-input>
                    </div>
                    <div class="col-6">
                        <mercur-input data-test="updateOrderlineCarrierCostInput" v-model="details.carrierCost" @input="dirtyFields.push('carrierCost')">
                            Carrier costprice (€)
                        </mercur-input>
                    </div>
                </div>
            </div>
            <div class="selected-orders">
                <h2 class="selected-orders__title">Selected orders:</h2>
                <ul class="selected-orders__list">
                    <li v-for="orderLineNumber in selectedOrderLineNumbers" :key="orderLineNumber">
                        {{ orderLineNumber }}
                    </li>
                </ul>
            </div>
        </div>
        <div slot="footer">
            <mercur-button class="btn btn-raised text-uppercase" @click="closeDialog">
                Close
            </mercur-button>
            <mercur-button data-test="updateOrderlineUpdateBtn" class="btn btn-raised btn-yellow text-uppercase" @click="submit" :disabled="$v.$invalid || loading">
                Update
            </mercur-button>
        </div>
        <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
    </mercur-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
    requiredIf,
} from 'vuelidate/lib/validators'
import CONFIG from '@root/config'
import PrettySelect from '../utils/PrettySelect'
import moment from 'moment'
import UpdateOrderLineSkuDialog from './UpdateOrderLineSkuDialog'
import regions from 'country-region-data'

export default {
    name: 'UpdateOrderLineDetailsDialog',
    props: ['dialogActive', 'selectedOrderLines', 'allSuppliers', 'allServiceLevels'],
    components: { UpdateOrderLineSkuDialog, PrettySelect },
    mixins: [validationMixin],
    data () {
        return {
            activeStep: 'general',
            isEditingAttribute: false,
            isSupplierChanged: false,
            loading: false,
            details: null,
            selectedLegacySupplier: null,
            selectedLegacyServiceLevel: null,
            selectedFacility: null,
            selectedServiceLevel: null,
            suitableFacilities: null,
            suitableServiceLevels: null,
            stepsDone: {
                general: false,
                files: false,
            },
            general: {
                invoiceNumber: null,
                invoiceDate: new Date(),
            },
            files: [],
            dirtyFields: [],
            countryList: regions,
        }
    },
    computed: {
        canEditSku () {
            return this.numberOfOrderLinesSelected === 1
        },
        canEditFacility () {
            return this.numberOfOrderLinesSelected === 1
        },
        canEditServiceLevel () {
            return this.numberOfOrderLinesSelected === 1
        },
        localDialogActive: {
            get () {
                return this.dialogActive
            },
            set (value) {
                this.$emit('update:dialogActive', value)
            },
        },
        numberOfOrderLinesSelected () {
            return this.selectedOrderLines.length
        },
        selectedOrderLineNumbers () {
            return this.selectedOrderLines.flatMap(({ orderNumber, orderLineNumber }) => `${orderNumber} - ${orderLineNumber}`)
        },
        selectedOrderLineIds () {
            return this.selectedOrderLines.flatMap(({ orderLineId }) => orderLineId)
        },
        isLegacyOrders () {
            return this.selectedOrderLines.every(orderLine => !orderLine.productHash)
        },
        attributes () {
            if (this.numberOfOrderLinesSelected !== 1) {
                return null
            }
            const attributes = JSON.parse(JSON.stringify(this.selectedOrderLines[0].supplierAttributes))
            attributes.sort((a, b) => a.attributeName.localeCompare(b.attributeName))
            return attributes
        },
    },
    validations: {
        selectedFacility: {
            required: requiredIf(function (model) {
                if (this.isLegacyOrders) {
                    return false
                }
                return model.selectedServiceLevel
            }),
        },
        selectedServiceLevel: {
            required: requiredIf(function (model) {
                if (this.isLegacyOrders) {
                    return false
                }
                return model.selectedFacility
            }),
        },
    },
    methods: {
        onServiceLevelChanged (serviceLevel) {
            this.$set(this.details, 'carrierCost', serviceLevel.convertedCarrierCost)
            this.dirtyFields.push('carrierCost')
        },
        getServiceLevelOptionLabel (serviceLevel) {
            const cost = this.$options.filters.asMoney(serviceLevel.convertedCarrierCost)
            return `${serviceLevel.serviceLevelName} (${serviceLevel.deliveryDays} days) ${cost}`
        },
        handleSkuChange ({ selectedAlternativeSKU, facility }) {
            this.$set(this, 'suitableFacilities', [facility])
            this.$set(this, 'selectedFacility', facility)
            this.isEditingAttribute = false
            this.$set(this.details, 'sku', selectedAlternativeSKU.sku)
            this.$set(this.details.producer, 'productionDays', selectedAlternativeSKU.productionDays)
            this.$set(this.details, 'productCost', selectedAlternativeSKU.totalCost)
            this.dirtyFields.push('sku')
            this.dirtyFields.push('productCost')
            this.onFacilityChanged()
        },
        onFacilityChanged () {
            this.$set(this.details, 'orderLineStatus', 'READY_FOR_PRODUCTION')
            this.dirtyFields.push('orderLineStatus')

            setTimeout(() => {
                this.isSupplierChanged = true
            }, 10)
        },
        getAvailableOrderLineStatusses () {
            return [
                'READY_FOR_PRODUCTION',
                'IN_PRODUCTION',
                'PRODUCED',
                'ON_HOLD',
                'AWAITING_DELIVERY',
                'DELIVERED',
            ].map(orderLineStatus => {
                return {
                    title: this.$options.filters.upperCaseFirst(this.$options.filters.beautify(orderLineStatus).toLowerCase()),
                    value: orderLineStatus,
                }
            })
        },
        handleDialogOpened () {
            if (this.numberOfOrderLinesSelected === 0) {
                this.$set(this, 'details', {})
                return
            }

            this.$set(this, 'details', {
                ...this.selectedOrderLines[0],
            })
            this.$set(this.details, 'carrier', this.details.supplierCarrier)
            this.$set(this.details, 'producer', this.details.supplierProducer)

            this.$set(this.details, 'producerSku', this.details.supplierProducerSku)
            this.$set(this.details, 'producerReference', this.details.supplierProducerReference)
            this.$set(this.details, 'productCost', this.details.supplierProductCost)
            this.$set(this.details, 'carrierCost', this.details.supplierCarrierCost)

            this.$set(this, 'isSupplierChanged', false)

            this.$set(this, 'selectedFacility', null)

            this.$set(this, 'selectedLegacySupplier', null)
            this.$set(this, 'selectedLegacyServiceLevel', null)

            if (this.isLegacyOrders) {
                this.$set(this, 'selectedLegacySupplier', this.allSuppliers.find(supplier => {
                    return supplier.supplierId === this.details.producer.supplierId || supplier.sourceId === this.details.supplierSourceId
                }))
                this.$set(this, 'selectedLegacyServiceLevel', this.allServiceLevels.find(serviceLevel => {
                    if (this.details.carrier && this.details.carrier.serviceLevelId === serviceLevel.serviceLevelId) {
                        return true
                    }
                    if (serviceLevel.serviceLevelId === this.details.serviceLevelId) {
                        return true
                    }
                    if (serviceLevel.serviceLevelName === this.details.carrierName) {
                        return true
                    }
                    return serviceLevel.serviceLevelName === this.details.carrier.carrierName
                }))
            }

            this.getSuitableFacilitiesForOrderLineIds(this.selectedOrderLineIds)
        },

        getSuitableFacilitiesForOrderLineIds (orderLineIds) {
            this.$set(this, 'suitableFacilities', null)
            this.$set(this, 'suitableServiceLevels', null)
            const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.GET_SUITABLE.FACILITIES
            this.addJob(url)
            this.$api.post(url, {
                orderLineIds,
            }).then(({ data }) => {
                this.$set(this, 'suitableFacilities', data.data.suitableFacilities.map(facility => {
                    const supplier = this.allSuppliers.find(({ supplierId }) => supplierId === facility.supplierId)
                    if (supplier) {
                        facility.facilityName = `${supplier.supplierName} - ${facility.facilityName}`
                    }

                    return facility
                }))
                this.selectedFacility = this.suitableFacilities.find(({ facilityId }) => facilityId === this.details.producer.facilityId)
            }).catch((data) => {
                if (!this.isSupplier) {
                    this.$root.$emit('notification:global', {
                        message: `Getting suitable facilities for orderlines failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },
        getCountryCode (address) {
            if (address.countryCode && address.countryCode.length === 2) {
                return address.countryCode
            }
            if (address.country_code && address.country_code.length === 2) {
                return address.country_code
            } else {
                let country = this.countryList.find(country => country.countryName === address.country)
                return country.countryShortCode
            }
        },

        getSuitableServiceLevelsForOrderLinesAndFacility (orderLineIds, facility) {
            this.$set(this, 'suitableServiceLevels', null)
            const payload = {
                orderLineIds,
                facilityId: facility.facilityId,
                supplierId: facility.supplierId,
                productHash: this.details.productHash,
                countryTo: this.getCountryCode(this.details.address),
                sku: this.details.sku,
                quantity: this.details.quantity,
            }

            const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.GET_SUITABLE.SERVICELEVELS_FOR_SKU_AND_FACILITY

            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                this.$set(this, 'suitableServiceLevels', data.data.suitableServiceLevels)
                this.selectedServiceLevel = this.suitableServiceLevels.find(suitableServiceLevel => {
                    if (suitableServiceLevel.serviceLevelId === this.details.carrier.serviceLevelId) {
                        return true
                    }

                    if (suitableServiceLevel.serviceLevelName === this.details.carrier.carrierName) {
                        return true
                    }

                    return false
                })
            }).catch((data) => {
                if (!this.isSupplier) {
                    this.$root.$emit('notification:global', {
                        message: `Getting suitable service levels for facility failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },

        closeDialog () {
            this.$set(this, 'localDialogActive', false)
        },

        submit () {
            this.loading = true

            if (this.selectedFacility && this.selectedServiceLevel) {
                this.$set(this.details.producer, 'producerName', this.selectedFacility.facilityName)
                this.$set(this.details.producer, 'facilityId', this.selectedFacility.facilityId)
                this.$set(this.details.producer, 'supplierId', this.selectedFacility.supplierId)
                this.$set(this.details.producer, 'sourceId', this.selectedFacility.supplierId)
                this.$set(this.details.carrier, 'carrierName', this.selectedServiceLevel.serviceLevelName)
                this.$set(this.details.carrier, 'shippingDays', this.selectedServiceLevel.deliveryDays)
                this.dirtyFields.push('carrier')
                this.dirtyFields.push('producer')
            }

            if (this.selectedLegacySupplier) {
                this.$set(this.details.producer, 'producerName', this.selectedLegacySupplier.supplierName)
                this.$set(this.details.producer, 'sourceId', this.selectedLegacySupplier.sourceId)
                this.$set(this.details.producer, 'supplierId', this.selectedLegacySupplier.supplierId)
                this.dirtyFields.push('producer')
            }

            if (this.selectedLegacyServiceLevel) {
                this.$set(this.details.carrier, 'carrierName', this.selectedLegacyServiceLevel.serviceLevelName)
                this.$set(this.details.carrier, 'sourceId', this.selectedLegacyServiceLevel.sourceId)
                this.$set(this.details.carrier, 'serviceLevelId', this.selectedLegacyServiceLevel.serviceLevelId)
                this.dirtyFields.push('carrier')
            }

            const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.UPDATE_DETAILS

            this.dirtyFields = [...new Set(this.dirtyFields)]

            const payload = {
                orderLines: this.selectedOrderLines.map(orderLine => {
                    const orderDetails = {
                        orderLineId: orderLine.orderLineId,
                        orderId: orderLine.orderId,
                    }

                    this.dirtyFields.forEach(field => {
                        let value = this.details[field]
                        if (field === 'targetDispatchDate') {
                            value = moment(value).format('YYYY-MM-DD')
                        }
                        this.$set(orderDetails, field, value)
                    })
                    return orderDetails
                }),
                accountId: this.selectedOrderLines[0].accountId,
            }

            this.addJob(payload)
            this.$api.post(url, payload).then(({ data }) => {
                setTimeout(() => {
                    this.closeDialog()
                    this.$emit('orderLineDetailsChanged', true)

                    this.$root.$emit('notification:global', {
                        message: `Order line details successfully updated`,
                    })
                }, 3000)
            }).catch((data) => {
                this.$root.$emit('notification:global', {
                    message: `Updating order line details failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                setTimeout(() => {
                    this.loading = false
                    this.finishJob(payload)
                }, 2500)
            })
        },
    },
    watch: {
        selectedOrderLines () {
            this.handleDialogOpened()
        },
        selectedFacility () {
            if (!this.selectedFacility) {
                this.$set(this, 'selectedServiceLevel', null)
                return
            }
            this.getSuitableServiceLevelsForOrderLinesAndFacility(this.selectedOrderLines, this.selectedFacility)
        },
    },
    created () {
        this.handleDialogOpened()
    },
}
</script>
<style lang="scss" scoped>
/deep/ .dialog__wrapper {
    max-width: 1000px;
    width: 90vw;
}
.selected-orders {
    font-size: 12px;
    &__list {
        max-height: 80px;
        overflow: scroll;
        margin: 0;
    }
    &__title {
        font-size: 13px;
        font-weight: normal
    }
}
</style>
