<template>
    <div class="vue-daterange-picker">
        <div class="form-control reportrange-text" @click="togglePicker">
            <slot
                name="input"
                :startDate="start"
                :endDate="end"
                :ranges="ranges"
            >
                <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>&nbsp;
                <span>{{rangeText}}</span>
                <b class="caret"></b>
            </slot>
        </div>
        <transition name="slide-fade" mode="out-in">
            <div
                class="daterangepicker dropdown-menu ltr"
                :class="pickerStyles"
                v-if="open"
                v-on-clickaway="clickAway"
            >
                <div class="calendars row no-gutters">
                    <slot name="ranges" v-if="ranges !== false">
                        <calendar-ranges
                            class="col-12"
                            @clickRange="clickRange"
                            :ranges="ranges"
                        ></calendar-ranges>
                    </slot>

                    <div class="drp-calendar col left" :class="{single: singleDatePicker}">
                        <div class="daterangepicker_input d-none d-sm-block" v-if="false">
                            <input class="input-mini form-control" type="text" name="daterangepicker_start"
                                   :value="startText"/>
                            <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                        </div>
                        <div class="calendar-table">
                            <calendar
                                :monthDate="monthDate"
                                :locale-data="locale"
                                :start="start" :end="end"
                                :minDate="min" :maxDate="max"
                                :show-dropdowns="showDropdowns"
                                @nextMonth="nextMonth" @prevMonth="prevMonth"
                                @change-month="changeLeftMonth"
                                @dateClick="dateClick" @hoverDate="hoverDate"
                                :showWeekNumbers="showWeekNumbers"
                            ></calendar>
                        </div>
                        <calendar-time v-if="timePicker"
                           @update="onUpdateStartTime"
                           @change-month="changeRightMonth"
                           :miniute-increment="timePickerIncrement"
                           :hour24="timePicker24Hour"
                           :second-picker="timePickerSeconds"
                           :current-time="start"
                        />
                    </div>

                    <div class="drp-calendar col right" v-if="!singleDatePicker">
                        <div class="daterangepicker_input" v-if="false">
                            <input class="input-mini form-control" type="text" name="daterangepicker_end"
                                   :value="endText"/>
                            <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                        </div>
                        <div class="calendar-table">
                            <calendar
                                :monthDate="nextMonthDate"
                                :locale-data="locale"
                                :start="start" :end="end"
                                :minDate="min" :maxDate="max"
                                :show-dropdowns="showDropdowns"
                                @nextMonth="nextMonth" @prevMonth="prevMonth"
                                @change-month="changeRightMonth"
                                @dateClick="dateClick" @hoverDate="hoverDate"
                                :showWeekNumbers="showWeekNumbers"
                            ></calendar>
                        </div>
                        <calendar-time v-if="timePicker"
                           @update="onUpdateEndTime"
                           :miniute-increment="timePickerIncrement"
                           :hour24="timePicker24Hour"
                           :second-picker="timePickerSeconds"
                           :current-time="end"
                        />
                    </div>
                </div>

                <div class="drp-buttons" v-if="!autoApply">

                    <span class="drp-selected">
                        <slot
                            name="preview-range"
                            :startDate="start"
                            :endDate="end"
                            :startText="startText"
                            :endText="endText"
                            :rangeText="rangeText"
                        >
                            {{rangeText}}
                        </slot>
                    </span>
                    <mercur-button
                        class="btn btn-raised clearBtn btn-sm text-uppercase px-3"
                        type="button"
                        @click="clear"
                    >{{locale.clearLabel}}
                    </mercur-button>
                    <mercur-button
                        class="btn btn-raised btn-yellow applyBtn btn-sm text-uppercase px-3"
                        :disabled="in_selection"
                        type="button"
                        @click="clickedApply"
                    >{{locale.applyLabel}}
                    </mercur-button>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'

export default {
    name: 'DateRangePicker',
    mixins: [DateRangePicker],
    props: {
        allDay: {
            required: false,
            default: false,
        },
    },
    data () {
        return {
            locale: {
                direction: 'ltr', // direction of text
                format: this.allDay ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm', // fomart of the dates displayed
                separator: ' to ', // separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                clearLabel: 'Clear',
                weekLabel: 'W',
                timePicker: true,
                timePicker24Hour: true,
                firstDay: 1, // ISO first day of week - see moment documenations for details
                showWeekNumbers: true, // show week numbers on each row of the calendar
            },
        }
    },
    methods: {
        clear () {
            this.$emit('update', { startDate: null, endDate: null })
            this.open = false
        },
    },
}
</script>
<style lang="scss" scoped>
    .clearBtn {
        margin-left: auto !important;
    }
</style>
