<template>
    <mercur-dialog :is-open.sync="dialogActive" width="70%">
        <div slot="header"><h3>Add invoice</h3></div>
        <div slot="default">
            <mercur-stepper :activeStep.sync="activeStep">
                <mercur-step id="general" title="General" >
                    <div>
                        <mercur-input
                            data-test="invoicingPopupInvoiceNumber"
                            v-model="$v.general.invoiceNumber.$model"
                            :disabled="loading"
                            required
                            :class="{'form-invalid': $v.general.invoiceNumber.$error}">
                            <i class="fas fa-hashtag"></i>
                            Invoice number
                            <template slot="note">
                                <span class="form-error" v-if="!$v.general.invoiceNumber.$error">Invoice number is required</span>
                            </template>
                        </mercur-input>
                        <mercur-input
                            data-test="invoicingPopupInvoiceDate"
                            v-model="$v.general.invoiceDate.$model"
                            type="date"
                            required
                            :class="{'form-invalid': $v.general.invoiceDate.$error}">
                            Invoice date
                            <template slot="note">
                                <span class="form-error" v-if="!$v.general.invoiceNumber.$error">Invoice date is required</span>
                            </template>
                        </mercur-input>
                        {{$v.general.invoiceDate.$model}}
                    </div>
                </mercur-step>
                <mercur-step disabled id="files" title="Files">
                    <div data-test="invoicingPopupStepFiles"  class="fill full-height-layout">
                        <filedropper @uploadUpdate="uploadUpdate" :url="uploadUrl"></filedropper>
                        <filedropper-list v-model="$v.files.$model" class="fill full-height-layout" ref="uploadedInvoiceList"></filedropper-list>
                    </div>
                </mercur-step>
            </mercur-stepper>
        </div>
        <div class="flex justify-content-between">
            <div>
                Adding {{invoiceType}} invoice for order line id's:<br />{{selectedOrderLineNumbers.join(', ')}}
            </div>
            <mercur-button data-test="invoicingPopupNext" class="btn text-uppercase" @click="nextStep()" :disabled="$v[activeStep].$invalid || loading">
                <template v-if="activeStep === 'files'">
                    Submit
                </template>
                <template v-else>
                    Next step
                </template>
            </mercur-button>
        </div>
        <mercur-progress-bar indeterminate v-if="loading"></mercur-progress-bar>
    </mercur-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
    required,
    minLength,
} from 'vuelidate/lib/validators'
import moment from 'moment'
import Filedropper from '@/components/utils/Filedropper'
import FiledropperList from '@/components/utils/FiledropperList'
import CONFIG from '@root/config'

export default {
    name: 'InvoicingPopup',
    components: { FiledropperList, Filedropper },
    props: {
        invoicingActive: {},
        selectedRows: {},
        invoiceType: {
            type: String,
            default: 'debit',
        },
    },
    mixins: [validationMixin],
    data () {
        return {
            activeStep: 'general',
            uploadUrl: CONFIG.API.ROUTES.ORDERS.INVOICES.UPLOAD_BULK.replace('{supplierId}', this.supplierId),
            loading: false,
            stepsDone: {
                general: false,
                files: false,
            },
            general: {
                invoiceNumber: null,
                invoiceDate: new Date(),
            },
            files: [],
        }
    },
    computed: {
        dialogActive: {
            get () {
                return this.invoicingActive
            },
            set (value) {
                this.$emit('update:invoicingActive', value)
            },
        },
        selectedOrderLineNumbers () {
            return this.selectedRows.flatMap(({ orderNumber, orderLineNumber }) => `${orderNumber} - ${orderLineNumber}`)
        },
    },
    validations: {
        general: {
            invoiceNumber: {
                required,
            },
            invoiceDate: {
                required,
            },
        },
        files: {
            required,
            minLength: minLength(1),
        },
    },
    methods: {
        uploadUpdate (value) {
            this.$refs.uploadedInvoiceList.uploadUpdate(value)
        },
        nextStep () {
            if (this.activeStep === 'general') {
                this.stepsDone.general = true
                this.activeStep = 'files'
                return
            }

            const url = CONFIG.API.ROUTES.ORDERS.INVOICES.CREATE.replace('{supplierId}', this.supplierId)
            this.addJob(url)
            this.loading = true

            const payload = {
                supplierInvoiceNumber: this.general.invoiceNumber,
                supplierInvoiceDate: moment(this.general.invoiceDate).format('YYYY-MM-DD HH:MM:SS'),
                supplierInvoiceStatus: 'ACTIVE',
                objectNames: this.files,
                supplierInvoiceType: this.invoiceType.toUpperCase(),
            }

            const orderLines = this.selectedRows.map(orderLine => {
                return {
                    orderLineId: orderLine.orderLineId,
                    orderId: orderLine.orderId,
                    orderLineNumber: orderLine.orderLineNumber,
                    orderNumber: orderLine.orderNumber,
                    dispatchDateFrom: orderLine.dispatchDateFrom,
                    dispatchDateTo: orderLine.dispatchDateTo,

                }
            })

            if (this.invoiceType === 'credit') {
                payload.complaints = orderLines
            } else {
                payload.orderLines = orderLines
            }
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Invoice details submitted successfully.`,
                })
                this.$emit('orderLineDetailsChanged', true)
                this.dialogActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving invoice details failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
}
</script>
