<template>
    <mercur-card v-if="isAllowedToView" class="full-height-layout fill mx-4">
        <grid-header :quickSearch.sync="filters.search">
            {{filterStatus.fullTitle}} {{orderLineOverviewType | beautify}} {{orderOrigin === 'quote' ? 'from quotes' : ''}}
            <div slot="filters" class="daterangepicker-wrapper" v-if="isToInvoice">
                <date-range-picker
                    @update="filterDateRangeUpdate"
                    :timePicker="false"
                    :dateRange="filters.dateRange"
                    :ranges="false"
                    opens="right"
                    ref="dateRangePicker"
                    :show-week-numbers="true"
                >
                    <template slot="preview-range" slot-scope="preview">
                        <strong>{{preview.startText}}</strong>
                        &nbsp;to&nbsp;
                        <strong>{{preview.endText}}</strong>
                    </template>
                    <template slot="input">
                        <mercur-input
                            v-model="parsedDateRange"
                            :disabled="loading"
                            @keypress.enter.prevent="handleManualDateInput">
                            Date range
                        </mercur-input>
                    </template>
                </date-range-picker>
            </div>
        </grid-header>
        <div v-if="!isToInvoice && !isVirtualSupplierOrderlineOverview">
            <div class="manual-order-numbers">
                <mercur-textarea v-model="manualOrderNumberInput">
                    Order numbers <em v-if="manualOrderNumberInput" @click="manualOrderNumberInput = null" class="manual-order-numbers__clear"><strong>(clear)</strong></em>
                </mercur-textarea>
            </div>
        </div>
        <data-table
            :items="filteredItems"
            :options="options"
            class="border hover-actions"
            ref="grid"
            :selected-rows.sync="selectedRows"
        ></data-table>
        <portal to="sidebarRight">
            <transition name="slide-fade">
                <router-view
                    class="sidebarRight"
                    ref="routeview"
                    :filter-status="filterStatus"
                    :is-virtual-supplier-orderline-overview="isVirtualSupplierOrderlineOverview"
                ></router-view>
            </transition>
        </portal>
        <portal to="actionbar">
            <div class="ml-30 mb-4 pb-2 flex">
                <mercur-button class="btn btn-raised mr-2 text-uppercase" @click="selectAllVisibleRows">Select <template v-if="selectedRows.length">none</template><template v-else>
                    <template v-if="isToInvoice">all</template><template v-else>{{maxSelectedRows}}</template></template>
                </mercur-button>
                <div v-if="selectedRows.length">
                    <transition name="slide">
                        <div>
                            <template v-if="isToInvoice && isAllowedTo('SupplierCentral/addSupplierInvoice')">
                                <mercur-button
                                    class="btn btn-raised btn-yellow text-uppercase mr-2"
                                    @click="openStartInvoicing"
                                    :disabled="loading"
                                >
                                    Start invoicing ({{totalAmount | asMoney(selectedOrderlinesCurrencyCode)}})
                                </mercur-button>
                            </template>
                            <template v-if="!isToInvoice">
                                <mercur-button
                                    v-if="isAllowedToUpdateOrderLine"
                                    class="btn btn-raised btn-yellow text-uppercase mr-2"
                                    @click="openUpdateOrderLineStatus"
                                    :disabled="!isUpdateOrderLineButtonEnabled"
                                >
                                    Update order<template v-if="selectedRows.length > 1">s</template>
                                </mercur-button>
                                <mercur-menu data-test="downloadOrderMenu" class="download-section">
                                    <mercur-button class="btn btn-raised btn-lg mr-2 text-uppercase">Download <i class="fas fa-cloud-download-alt"></i></mercur-button>
                                    <div slot="dropdown">
                                        <mercur-item data-test="downloadOrderMenuOption" v-if="isAllowedTo('SupplierCentral/generateOrderLinesZip')" @click.native="downloadAssets" :disabled="loading"><span>Full package</span></mercur-item>
                                        <mercur-item v-if="isInProduction && isAllowedTo('SupplierCentral/generatePackingSlip')" @click.native="downloadPackingSlip" :disabled="loading"><span>Packing slip</span></mercur-item>
                                    </div>
                                </mercur-menu>
                                <span class="button-wrapper mr-2">
                                    <mercur-button v-if="showDownloadShippingLabel" class="btn btn-raised btn-yellow text-uppercase" @click="shippingLabelNumberDialogActive = true" :disabled="!canDownloadShippingLabel || loading">
                                        <mercur-tooltip v-if="!canDownloadShippingLabel">
                                            <template #label>
                                                Downloading a shipping label for multiple orders is only allowed for orders with matching delivery addresses and not shipped with <i>own carriers</i>
                                            </template>
                                            <span slot="default">Download shipping label</span>
                                        </mercur-tooltip>
                                        <span v-else>Download shipping label</span>
                                    </mercur-button>
                                </span>
                                <mercur-button v-if="showAddShipment" :disabled="loading" class="btn btn-raised btn-yellow text-uppercase" @click="openAddTrackingLinkDialog">
                                    Add manual tracking url
                                </mercur-button>
                            </template>
                             <template>
                                <mercur-button
                                    v-if="!isSupplier"
                                    class="btn btn-raised btn-yellow text-uppercase mr-2"
                                    @click="openRegisterDelay"
                                    :disabled="loading || showAlertInfoRegisterDelay">
                                    Register Delay
                                </mercur-button>
                            </template>
                            <template>
                                <span v-if="showAlertInfoRegisterDelay"  class="warning"> <i class="mr-2 fas fa-exclamation-triangle" aria-hidden="true"></i>Isn't possible register delays for orderline(s) already registered.</span>
                            </template>
                        </div>
                    </transition>
                </div>
            </div>
        </portal>
        <invoicing-popup
            :invoicing-active.sync="invoicingActive"
            :selected-rows="selectedOrderLines"
            @orderLineDetailsChanged="onActionFinished"
            v-if="isAllowedTo('SupplierCentral/addSupplierInvoice')"
        ></invoicing-popup>

        <update-order-line-details-dialog
            :dialog-active.sync="updateOrderLineDetailsDialogActive"
            :selected-order-lines="selectedOrderLines"
            @orderLineDetailsChanged="onActionFinished"
            :all-suppliers="allSuppliers"
            :all-service-levels="allServiceLevels"
            v-if="!isSupplier && isAllowedTo('SupplierCentral/updateOrderLine')"
        ></update-order-line-details-dialog>

        <mercur-dialog :is-open.sync="addManualTrackingDialog.active" width="90%">
            <div slot="header"><h3>Add shipment</h3></div>
            <form @submit.prevent="addManualTrackingDialog.confirm">
                <div slot="default">
                    <mercur-textarea readonly disabled v-model="addManualTrackingDialog.shipmentReference">
                        Shipment reference (read only)
                    </mercur-textarea>
                    <mercur-input
                        v-model="$v.addManualTrackingDialog.trackingLink.$model"
                        :class="{'form-invalid': $v.addManualTrackingDialog.trackingLink.$error}">
                        <i class="fas fa-link"></i>
                        Tracking url
                        <template slot="note"><span class="form-error" v-if="!$v.addManualTrackingDialog.trackingLink.url">Not a valid url</span></template>
                        <template slot="note"><span class="form-error" v-if="!$v.addManualTrackingDialog.trackingLink.required">Required</span></template>
                    </mercur-input>

                    <mercur-input
                        v-model.number="$v.addManualTrackingDialog.quantity.$model"
                        type="number"
                        :class="{'form-invalid': $v.addManualTrackingDialog.trackingLink.$error}">
                        Number of packages
                        <template slot="note">
                            <span class="form-error" v-if="!$v.addManualTrackingDialog.quantity.required">Required</span>
                        </template>
                    </mercur-input>
                </div>
                <div slot="footer" class="pb-1 position-relative">
                    <mercur-button class="btn text-uppercase" @click="addManualTrackingDialog.active = false">Close</mercur-button>
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" :disabled="addManualTrackingDialog.loading" type="submit">Add</mercur-button>
                    <mercur-progress-bar indeterminate v-if="addManualTrackingDialog.loading"></mercur-progress-bar>
                </div>
            </form>
        </mercur-dialog>
        <mercur-dialog :is-open.sync="updateStatus.active">
            <div slot="header"><h3>{{updateStatus.title}}</h3></div>
            <form @submit.prevent="updateStatus.confirm" class="select-new-status-modal">
                <div slot="default">
                    <p>{{updateStatus.description}}</p>
                    <mercur-select v-model="updateStatus.selectedStatus">
                        <template slot="label">Select a status</template>
                        <option value="READY_FOR_PRODUCTION">Ready for production</option>
                        <option value="IN_PRODUCTION">In production</option>
                        <option value="PRODUCED">Produced</option>
                    </mercur-select>
                </div>
                <div slot="footer">
                    <mercur-button class="btn text-uppercase" @click="updateStatus.active = false">Return</mercur-button>
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" :disabled="!updateStatus.selectedStatus" type="submit">Confirm</mercur-button>
                </div>
            </form>
        </mercur-dialog>

         <mercur-dialog :is-open.sync="complaintsListModal.open" >
            <div slot="header"><h3>Choose Complaints</h3></div>
            <data-table
                v-if="complaintsListModal.itemsList.length > 0"
                :items="complaintsListModal.itemsList"
                :options="optionsTableComplaints"
                class="border"
                ref="grid"
            ></data-table>
            <div class="text-center" v-if="complaintsListModal.itemsList.length < 1">No Complaints Awaiting for Supplier</div>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="registerDelayModal.open" >
            <div slot="header"><h3>{{registerDelayModal.editMode === true ? 'Edit Delay' : 'Register Delay' }}</h3></div>
            <form @submit.prevent="registerDelayModal.confirm">
                <div slot="default" class="full-height-layout fill">
                        <div :class="{'form-invalid': $v.registerDelayModal.delay.withComplaint.$error}">
                            <pretty-select class="form-field" :disabled="registeredDelayWithoutComplaint || (registerDelayModal.editMode === true && registerDelayModal.delay.withComplaint == 'Yes')"  required v-model="$v.registerDelayModal.delay.withComplaint.$model" :options="['Yes', 'No']" placeholder="Register a complaint">
                            </pretty-select>
                              <span class="form-error" v-if="$v.registerDelayModal.delay.withComplaint.$error">Required</span>
                        </div>
                    <template v-if="registerDelayModal.delay.withComplaint == 'Yes'">
                        <div :class="{'form-invalid': $v.registerDelayModal.delay.complaint.$dirty &&  ( !registerDelayModal.delay.complaint.complaintDepartments || registerDelayModal.delay.complaint.complaintDepartments.length<1)}" class="border px-3 py-2 mb-3">
                            <h4 class="mb-2 mt-1 font-weight-normal"><label>Complaint Department</label></h4>
                            <template  v-for="(department, departmentKey) in complaintDepartmentsWithReasons">
                                <span :key="department.title" class="mr-3" v-if="departmentKey !== 'CUSTOMER'">
                                    <mercur-checkbox :key="departmentKey" :value="departmentKey"  :id="`lab-${department.title}`" class="mb-2"  v-model="$v.registerDelayModal.delay.complaint.complaintDepartments.$model">{{ department.title }}</mercur-checkbox>
                                </span>
                            </template>
                            <span class="form-error" v-if="$v.registerDelayModal.delay.complaint.$dirty &&  (!registerDelayModal.delay.complaint.complaintDepartments || registerDelayModal.delay.complaint.complaintDepartments.length<1)">Required</span>
                        </div>
                          <div v-if="registerDelayModal.delay.complaint.complaintDepartments.length > 0">
                            <div class="row">
                                <div v-for="complaintDepartment in registerDelayModal.delay.complaint.complaintDepartments" :key="complaintDepartment" :class="{'form-invalid': $v.registerDelayModal.delay.complaint.$dirty &&  (!registerDelayModal.delay.complaint.complaintTypes[complaintDepartment])}" class="border px-3 py-2 mb-3 col-6">
                                    <mercur-select v-model="registerDelayModal.delay.complaint.complaintTypes[complaintDepartment]">
                                        <template #label>Complaint Type for {{ complaintDepartment }}</template>
                                        <option v-for="(item, index) in complaintDepartmentsWithReasons[complaintDepartment].items" :value="item" :key="`item - ${index}`">{{ item }}</option>
                                        <template #note>
                                            <span class="form-error" v-if="$v.registerDelayModal.delay.complaint.$dirty &&  (!registerDelayModal.delay.complaint.complaintTypes[complaintDepartment])">Complaint types are required for all departments.</span>
                                        </template>
                                    </mercur-select>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap complaint-costs">
                            <mercur-input :class="{'form-invalid': $v.registerDelayModal.delay.complaint.supplierComplaintCostPercentage.$error}"
                                class="form-field mr-3"
                                v-model.number="$v.registerDelayModal.delay.complaint.supplierComplaintCostPercentage.$model"
                                type="number" >
                                Complaint costs %
                                <template slot="note">
                                    <span class="form-error" v-if="$v.registerDelayModal.delay.complaint.supplierComplaintCostPercentage.$error">Required</span>
                                </template>
                            </mercur-input>
                            <p class="mt-auto mb-auto ml-4 complaint-info-price">Orderline costs : % {{registerDelayModal.delay.complaint.supplierComplaintCostPercentage || 0}} </p>
                        </div>
                        <mercur-textarea placeholder="Complaint Description" v-model="registerDelayModal.delay.complaint.description"></mercur-textarea>
                    </template>
                    <mercur-textarea v-if="registerDelayModal.delay.withComplaint !== 'Yes'" placeholder="Reason for Delay" v-model="registerDelayModal.delay.reason">
                    </mercur-textarea>
                    <mercur-input v-model="registerDelayModal.delay.targetDispatchDate" type="date">New dispatch Date</mercur-input>
                    <mercur-checkbox v-model="registerDelayModal.delay.shareWithMerchant" :disabled="shareDelayWithMerchatisDisabled()">Share delay with Merchant</mercur-checkbox>

                    <div class="selected-orders mt-4">
                        <h2 class="selected-orders__title">Selected orders:</h2>
                        <ul class="selected-orders__list">
                            <li v-for="orderLine in selectedRowsRegisterDelay" :key="orderLine.orderNumber+'-'+orderLine.orderLineNumber">
                                {{`${orderLine.orderNumber} - ${orderLine.orderLineNumber}`}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex mt-3" slot="footer">
                    <div class="mr-auto">
                        <mercur-button class="btn text-uppercase" @click="registerDelayModal.open = false">Close</mercur-button>
                    </div>
                    <div class="ml-auto">
                        <mercur-button class="btn btn-raised btn-yellow text-uppercase"  type="submit" :disabled="loading">{{registerDelayModal.editMode === true || registeredDelayWithoutComplaint ? 'Save' : 'Create'}}</mercur-button>
                        <mercur-button v-if="registeredDelayWithoutComplaint" class="btn btn-raised btn-yellow text-uppercase" :disabled="loading" @click="convertDelayToComplaint()">Convert to Complaint</mercur-button>
                    </div>
                </div>
            </form>
        </mercur-dialog>

        <generate-shipping-labels-dialog
            :dialog-active.sync="shippingLabelNumberDialogActive"
            :is-loading="shippingLabelNumberDialogLoading"
            @downloadShippingLabel="downloadShippingLabel"
            :can-show-package-details="isInternationalShipment"
            :orderlines="selectedOrderLines"
        ></generate-shipping-labels-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import { debounce } from 'debounce'
import moment from 'moment'
import DataTable from '@/components/DataTable'
import InvoicingPopup from '@/components/orderlines/InvoicingPopup'
import GridHeader from '@/components/GridHeader'
import UpdateOrderLineDetailsDialog from '../../components/orderlines/UpdateOrderLineDetailsDialog'
import DateRangePicker from '../../components/utils/DateRangePicker'
import { validationMixin } from 'vuelidate'
import {
    url,
    required,
} from 'vuelidate/lib/validators'
import GenerateShippingLabelsDialog from '../../components/orderlines/GenerateShippingLabelsDialog'
import PrettySelect from '../../components/utils/PrettySelect'
import complaintDepartmentsWithReasons from '@/langs/complaintDepartmentsWithReasons.json'
export default {
    name: 'SupplierOrderlines',
    components: { GenerateShippingLabelsDialog, DateRangePicker, UpdateOrderLineDetailsDialog, GridHeader, InvoicingPopup, DataTable, PrettySelect },
    mixins: [validationMixin],
    props: {
        isVirtualSupplierOrderlineOverview: {
            required: false,
            default: false,
        },
        orderLineOverviewType: {
            required: false,
            default: '',
        },
        orderOrigin: {
            default: 'cart',
            required: false,
        },
    },
    data () {
        return {
            maxSelectedRows: 10,
            shippingLabelNumberDialogActive: false,
            shippingLabelNumberDialogLoading: false,
            updateOrderLineDetailsDialogActive: false,
            numberOfShippingLabels: 1,
            shippingLabelDetails: [],
            invoicingActive: false,
            addManualTrackingDialog: {
                active: false,
                shipmentReference: null,
                trackingLink: null,
                quantity: null,
                loading: false,
            },
            url: null,
            requestPayload: null,
            items: null,
            loading: false,
            options: null,
            manualOrderNumberInput: null,
            filters: {
                search: '',
                dateRange: {
                    startDate: moment(),
                    endDate: moment(),
                },
            },
            complaintDepartmentsWithReasons: complaintDepartmentsWithReasons,
            numberOfAttempts: 0,
            allSuppliers: null,
            allServiceLevels: null,
            baseOptions: {
                columns: {
                    'Time': {
                        field: 'dateUpdated',
                        sortable: true,
                        valueFormatter ({ value }) {
                            return moment(value).format('L LT')
                        },
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: ({ node }) => {
                            return node.selected || this.selectedRows.length < this.maxSelectedRows
                        },
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Supplier': {
                        field: 'supplierProducer.supplierName',
                        valueGetter: ({ data }) => {
                            const supplierId = data.supplierProducer ? data.supplierProducer.supplierId : data.producer.supplierId

                            if (data.supplierProducer && data.supplierProducer.supplierName) {
                                return data.supplierProducer.supplierName
                            }

                            if (this.allSuppliers !== null) {
                                const supplier = this.allSuppliers.find(supplier => supplier.supplierId === supplierId)

                                if (supplier) {
                                    return supplier.supplierName
                                }
                            }
                            return supplierId
                        },
                        hide: true,
                        sortable: true,
                        virtualSupplierField: ['orders', 'delays', 'pre_delays'],
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                        link: (orderLineId, data) => {
                            const supplierId = data.supplierProducer ? data.supplierProducer.supplierId : data.producer.supplierId

                            return {
                                name: 'SupplierView',
                                params: {
                                    supplierId,
                                },
                            }
                        },
                    },
                    'Order Id': {
                        valueGetter: ({ data }) => {
                            const value = [`${data.orderNumber}-${data.orderLineNumber}`]

                            if (data.quote === true) {
                                value.push('(quote)')
                            }
                            if (data.delayedComplaintId || data.isDelayed) {
                                if (value[1]) {
                                    value[1] += data.isDelayed && !data.delayedComplaintId ? ' (registered - Without Complaint)' : ' (registered - With Complaint)'
                                } else {
                                    value.push(data.isDelayed && !data.delayedComplaintId ? ' (registered - Without Complaint)' : ' (registered - With Complaint)')
                                }
                            }
                            return value
                        },
                        autoHeight: true,
                        sortable: true,
                        field: 'orderLineId',
                        colId: 'orderNumber',
                        minWidth: 190,
                        link: (orderLineId, data) => {
                            return this.getOrderLineRoute(data)
                        },
                        icon: (data) => {
                            return data.reprintedFromOrderLineId ? 'fas fa-paste' : null
                        },
                        tooltipText: () => 'This orderline is a reprint.',
                        cellClass: ({ data }) => {
                            const classes = ['ag-grid__cell', 'ag-grid__cell--small']
                            if (data.orderLineId === this.$route.params.orderLineId) {
                                classes.push('ag-grid__cell', 'ag-grid__cell--strong')
                            }
                            if (data.isDelayed || data.delayedComplaintId) {
                                classes.push('ag-grid-line-heigth-0')
                            }
                            return classes
                        },
                    },
                    'Status': {
                        sortable: true,
                        field: 'orderLineStatus',
                        valueGetter: ({ data }) => {
                            return this.$options.filters.upperCaseFirst(this.$options.filters.beautify(data.orderLineStatus).toLowerCase())
                        },
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Target dispatch': {
                        sortable: true,
                        field: 'targetDispatchDate',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Dispatch date': {
                        sortable: true,
                        field: 'dispatchDate',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                        hide: true,
                        virtualSupplierField: ['orders', 'delays', 'pre_delays'],
                    },
                    'Communicated delivery': {
                        sortable: true,
                        hide: true,
                        field: 'deliveryDate',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                        virtualSupplierField: ['delays', 'pre_delays'],
                    },
                    'Carrier': {
                        sortable: true,
                        field: 'supplierCarrier.carrierName',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Description': {
                        sortable: true,
                        field: 'supplierDescription',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Destination': {
                        sortable: true,
                        field: 'destination',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Cost': {
                        sortable: true,
                        field: 'supplierProductCost',
                        valueFormatter: ({ value, data }) => {
                            return this.$options.filters.asMoney(value, this.getCurrencyCodeForOrderLine(data))
                        },
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'Extra days': {
                        field: 'extraDaysCommunication',
                        hide: true,
                        sortable: true,
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                        virtualSupplierField: ['delays', 'pre_delays'],
                    },
                    'Invoiced': {
                        sortable: true,
                        field: 'invoiced',
                        valueFormatter: ({ value }) => {
                            return value ? 'yes' : ''
                        },
                        hide: true,
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'dateFrom': {
                        sortable: true,
                        colId: 'dateFrom',
                        hide: true,
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                    'dateTo': {
                        sortable: true,
                        hide: true,
                        colId: 'dateTo',
                        cellClass: ['ag-grid__cell', 'ag-grid__cell--small'],
                    },
                },
                actionsWidth: 1,
                actions: [
                    {
                        text: 'Edit Delay',
                        icon: 'fas fa-edit',
                        label: 'Edit Delay',
                        hide: ({ data }) => !data.delayedComplaintId,
                        onClick: ({ data }) => {
                            this.openComplaintsList(data)
                        },
                    },
                    {
                        text: 'Edit Delay',
                        icon: 'fas fa-edit',
                        label: 'Edit Delay',
                        hide: ({ data }) => !data.isDelayed || data.delayedComplaintId,
                        onClick: (res) => {
                            this.registeredDelayWithoutComplaint = true
                            Object.assign(this.registerDelayModal.delay.complaint, {
                                complaintDepartments: [],
                                complaintTypes: {},
                                supplierComplaintCostPercentage: null,
                                description: null,
                            })
                            this.registerDelayModal.delay.withComplaint = 'No'
                            this.registerDelayModal.delay.reason = res.data.delayReason
                            if (res.data.targetDispatchDate) {
                                this.registerDelayModal.delay.targetDispatchDate = moment(res.data.targetDispatchDate).format('YYYY-MM-DD')
                            }
                            this.registerDelayModal.open = true
                            this.selectedRowsRegisterDelay = []
                            this.selectedRowsRegisterDelay.push(res.data)
                        },
                    },
                ],
                onSelectionChanged: this.selectionChanged,
                suppressCellSelection: false,
                getRowStyle: ({ data }) => {
                    if (data.orderLineId === this.$route.params.orderLineId) {
                        return {
                            'border-right': '3px solid #d0d0d0',
                        }
                    }
                },
            },
            selectedRows: [],
            selectedRowsRegisterDelay: [],
            updateStatus: {
                active: false,
                title: '',
                description: '',
                selectedStatus: null,
                confirm: () => {},
            },
            registerDelayModal: {
                open: false,
                editMode: false,
                delay: {
                    withComplaint: null,
                    reason: null,
                    targetDispatchDate: null,
                    shareWithMerchant: null,
                    complaint: {
                        complaintDepartments: [],
                        complaintTypes: {},
                        supplierComplaintCostPercentage: null,
                        description: null,
                    },
                },
                confirm: () => {
                    this.saveComplaintOrderLines()
                },
            },
            registeredDelayWithoutComplaint: false,
            complaintsListModal: {
                itemsList: [],
                open: false,
            },
            optionsTableComplaints: {
                columns: {
                    'Order Id': {
                        valueGetter: ({ data }) => {
                            const value = [`${data.orderNumber}-${data.orderLineNumber}`]
                            return value
                        },
                        field: 'orderLineId',
                        colId: 'orderNumber',
                    },
                    'Department': {
                        field: 'complaintDepartment',
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-edit',
                        text: 'Edit details',
                        tooltip: 'Edit details',
                        onClick: (data) => {
                            this.openRegisterDelay(data.data)
                        },
                    },
                ],
                pagination: false,
            },
            dateRangeFrom: null,
            dateRangeTo: null,
        }
    },
    validations: {
        addManualTrackingDialog: {
            trackingLink: {
                required,
                url,
            },
            quantity: {
                required,
            },
        },
        registerDelayModal: {
            delay: {
                withComplaint: {
                    required,
                },
                complaint: {
                    complaintDepartments: {
                        required,
                    },
                    complaintTypes: {
                        required,
                    },
                    supplierComplaintCostPercentage: {
                        required,
                    },
                },
            },
        },
    },
    methods: {
        fixMaxSelectedRows: debounce(function () {
            if (this.maxSelectedRowsAllowed >= 0 && this.selectedRows.length < this.maxSelectedRowsAllowed) {
                return
            }
            let selectionCount = 0
            this.$refs.grid.gridOptions.api.forEachNode(node => {
                if (node.selected) {
                    selectionCount++
                }
                if (this.maxSelectedRowsAllowed >= 0 && selectionCount > this.maxSelectedRowsAllowed) {
                    node.setSelected(false)
                }
            })
        }, 100),
        selectionChanged () {
            this.fixMaxSelectedRows()
            this.$refs.grid.gridOptions.api.redrawRows()
        },
        getCurrencyCodeForOrderLine (orderLine) {
            return this.$store.getters['suppliers/getCurrencyCodeForOrderLine'](orderLine)
        },
        selectAllVisibleRows () {
            this.$refs.grid.selectAllVisibleRows(this.maxSelectedRowsAllowed)
        },
        openUpdateOrderLineStatus () {
            this.updateOrderLineDetailsDialogActive = true
        },
        openStartInvoicing () {
            this.invoicingActive = true
        },
        shareDelayWithMerchatisDisabled () {
            if (this.registerDelayModal.delay.withComplaint === 'No') {
                this.registerDelayModal.delay.shareWithMerchant = true
                return this.registerDelayModal.delay.shareWithMerchant
            }
            return false
        },
        openComplaintsList (order) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.GET_BY_PARENT_ID
                .replace('{parentComplaintId}', order.delayedComplaintId)
            this.addJob(url)
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                const items = data.data.items.filter(w => w.orderNumber === order.orderNumber)
                this.complaintsListModal.itemsList = items
                this.complaintsListModal.open = true
            }).finally(() => {
                this.finishJob(url)
            })
        },
        openRegisterDelay (data) {
            this.complaintsListModal.open = false
            this.clearRegisterDelayModal()
            if (data.complaintId) {
                const url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.GETBYID.replace('{complaintId}', data.complaintId)
                this.addJob(`delay${data.complaintId}`)
                this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then((complaintResponse) => {
                    if (!complaintResponse.data) {
                        throw new Error('Getting the complaint details failed')
                    }
                    let complaint = complaintResponse.data.data
                    this.registerDelayModal.editMode = true
                    Object.assign(this.registerDelayModal.delay.complaint, complaint)
                    if (data.targetDispatchDate) {
                        this.registerDelayModal.delay.targetDispatchDate = moment(data.targetDispatchDate).format('YYYY-MM-DD')
                    }
                    if (complaint.complaintDepartment) {
                        this.registerDelayModal.delay.complaint.complaintDepartments.push(complaint.complaintDepartment)
                        this.registerDelayModal.delay.complaint.complaintTypes[complaint.complaintDepartment] = complaint.complaintType
                    }
                    this.registerDelayModal.delay.shareWithMerchant = complaint.shareWithMerchant
                    this.registerDelayModal.delay.withComplaint = 'Yes'
                    this.registerDelayModal.open = true
                    this.selectedRowsRegisterDelay = []
                    this.selectedRowsRegisterDelay.push(data)
                }).catch(res => {
                    this.$root.$emit('notification:global', {
                        message: `Getting complaint details failed. Please try again.`,
                        type: 'error',
                        errors: res,
                    })
                }).finally(() => {
                    this.loading = false
                    this.finishJob(`delay${data.complaintId}`)
                })
            } else {
                this.selectedRowsRegisterDelay = this.selectedRows
                this.registerDelayModal.open = true
            }
        },
        clearRegisterDelayModal () {
            this.registeredDelayWithoutComplaint = false
            this.registerDelayModal.editMode = false
            this.registerDelayModal.delay.withComplaint = null
            this.registerDelayModal.delay.reason = null
            this.registerDelayModal.delay.targetDispatchDate = null
            this.registerDelayModal.delay.shareWithMerchant = null
            this.registerDelayModal.delay.complaint.supplierComplaintCostPercentage = null
            this.registerDelayModal.delay.complaint.description = null
            this.$v.registerDelayModal.$reset()
            this.registerDelayModal.delay.complaint.complaintDepartments = []
            this.registerDelayModal.delay.complaint.complaintTypes = {}
        },
        convertDelayToComplaint () {
            this.loading = true
            let data = {}
            data.orderLines = this.selectedRowsRegisterDelay
            data.complaint = {}
            data.complaint.shareWithVirtualSupplier = true
            data.complaint.complaintDelayType = 'REGISTERED'
            data.withComplaint = 'Yes'
            data.complaint.description = ''
            data.complaint.complaintDepartment = 'SUPPLY_CHAIN'
            data.complaint.complaintType = 'Too Late :: Delayed Order'
            this.createUpdateComplaint(data, null)
        },
        saveComplaintOrderLines () {
            const data = JSON.parse(JSON.stringify(this.registerDelayModal.delay))

            if (this.registerDelayModal.delay.withComplaint === 'Yes') {
                this.$v.registerDelayModal.delay.complaint.$touch()
                if (this.$v.registerDelayModal.delay.complaint.$error) {
                    return
                }
                data.complaint.complaintDelayType = 'REGISTERED'
            } else {
                this.$v.registerDelayModal.delay.withComplaint.$touch()
                if (this.$v.registerDelayModal.delay.withComplaint.$error) {
                    return
                }
            }
            this.loading = true
            data.orderLines = this.selectedRowsRegisterDelay
            data.complaint.shareWithMerchant = data.shareWithMerchant
            data.complaint.shareWithVirtualSupplier = true

            if (this.registerDelayModal.editMode === true) {
                data.complaint.complaintType = data.complaint.complaintTypes[data.complaint.complaintDepartment]
                delete data.complaint.complaintDepartments
                delete data.complaint.complaintTypes
            }

            if (this.registerDelayModal.delay.withComplaint === 'No') {
                const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.UPDATE_DETAILS
                const payload = {
                    orderLines: data.orderLines.map(orderLine => {
                        const orderDetails = {
                            orderLineId: orderLine.orderLineId,
                            orderId: orderLine.orderId,
                            isDelayed: moment(new Date()).format('YYYY-MM-DD'),
                            delayReason: data.reason,
                            producerSku: orderLine.producerSku,
                        }
                        if (data.targetDispatchDate) {
                            orderDetails.targetDispatchDate = moment(data.targetDispatchDate).format('YYYY-MM-DD')
                        }
                        return orderDetails
                    }),
                    accountId: data.orderLines[0].accountId,
                }

                this.addJob(payload)
                this.$api.post(url, payload).then(({ res }) => {
                    this.onActionFinished()
                    this.$root.$emit('notification:global', {
                        message: `Order line details successfully updated`,
                    })
                }).catch((data) => {
                    this.$root.$emit('notification:global', {
                        message: `Updating order line details failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.loading = false
                    this.registerDelayModal.open = false
                    this.clearRegisterDelayModal()
                })
            } else {
                this.createUpdateComplaint(data, this.registerDelayModal.editMode === true)
            }
        },
        createUpdateComplaint (data, editMode) {
            const url = editMode ? CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.UPDATE.replace('{supplierId}', data.complaint.supplierId).replace('{complaintId}', data.complaint.complaintId) : CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.ADD
            this.addJob(this.registerDelayModal)
            this.$api.post(url, data).then(() => {
                this.onActionFinished()
                this.$root.$emit('notification:global', {
                    message: (this.registerDelayModal.editMode === true ? 'Delay successfully updated.' : 'Delay successfully registered.'),
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: this.registerDelayModal.editMode === true ? 'Updating delay failed. Please try again.' : 'Registering delay failed. Please try again.',
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.registerDelayModal.open = false
                this.complaintsListModal.open = false
                this.loading = false
                this.finishJob(this.registerDelayModal)
                this.clearRegisterDelayModal()
            })
        },
        getOrderLineRoute (params) {
            let routeName = 'OrderLineDetail'

            if (this.isVirtualSupplierOrderlineOverview) {
                routeName = 'VirtualSupplierOrderLineDetail'
            } else if (this.orderOrigin === 'quote') {
                routeName = 'QuoteOrderLineDetail'
            }

            return {
                name: routeName,
                params: {
                    ...this.$route.params,
                    orderId: params.orderId,
                    orderLineId: params.orderLineId,
                    selectedSupplierId: this.isVirtualSupplierOrderlineOverview ? params.supplierProducer.supplierId : this.supplierId,
                },
            }
        },
        onActionFinished () {
            try {
                this.$refs.grid.deselectAllRows()
            } catch (ex) { }

            this.getOrderLines({
                filterStatusValue: this.filterStatus.value,
            }, true)

            this.$root.$emit('order-line-updated')
        },
        openAddTrackingLinkDialog () {
            const selectedRows = this.selectedRows
            this.$v.addManualTrackingDialog.$reset()

            this.addManualTrackingDialog.shipmentReference = selectedRows.map(({ shipmentReference }) => shipmentReference).join(', ')
            this.addManualTrackingDialog.trackingLink = null
            this.addManualTrackingDialog.quantity = null
            this.addManualTrackingDialog.active = true
            this.addManualTrackingDialog.confirm = () => {
                this.$v.addManualTrackingDialog.$touch()
                if (this.$v.addManualTrackingDialog.$invalid) {
                    return
                }

                const payload = {
                    shipmentReference: this.addManualTrackingDialog.shipmentReference,
                    trackingLink: this.addManualTrackingDialog.trackingLink,
                    supplierId: this.isVirtualSupplierOrderlineOverview ? this.selectedRows[0].supplierProducer.supplierId : this.supplierId,
                    orderLines: this.selectedRows,
                    orderId: this.selectedRows[0].orderId,
                    quantity: this.addManualTrackingDialog.quantity,
                }

                const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.ADD_MANUAL_TRACKING_LINK.replace('{supplierId}', payload.supplierId)

                this.addJob(payload)
                this.addManualTrackingDialog.loading = true
                this.$api.post(url, payload).then(() => {
                    this.addManualTrackingDialog.active = false
                    this.onActionFinished()
                    this.$root.$emit('notification:global', {
                        message: `Tracking link successfully added.`,
                    })
                }).catch(data => {
                    this.$root.$emit('notification:global', {
                        message: `Adding tracking link failed. Please try again.`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.addManualTrackingDialog.loading = false
                    this.finishJob(payload)
                })
            }
        },
        openOrderStatusUpdateDialog (params) {
            const selectedRows = params.selectedRows || this.selectedRows

            this.updateStatus.active = true
            this.updateStatus.selectedStatus = null
            this.updateStatus.confirm = () => {
                this.updateStatus.active = false
                this.bulkUpdateOrderLines({
                    items: selectedRows.map((data) => {
                        return {
                            orderLine: data,
                            fields: {
                                orderLineStatus: this.updateStatus.selectedStatus,
                            },
                        }
                    }),
                })
            }

            if (selectedRows.length === 1) {
                this.updateStatus.title = 'Update status'
                this.updateStatus.description = `Please select the new status for order ${selectedRows[0].orderLineNumber} you have selected`
            } else {
                this.updateStatus.title = 'Bulk update status'
                this.updateStatus.description = `Please select the new status for the ${selectedRows.length} orders you have selected`
            }
        },
        downloadShippingLabel ({ numberOfShippingLabels, shippingLabelDetails }) {
            this.shippingLabelNumberDialogLoading = true
            this.downloadAssets({}, 'shippingLabel', numberOfShippingLabels, shippingLabelDetails).then(() => {
                this.shippingLabelNumberDialogActive = false
            }).finally(() => {
                this.shippingLabelNumberDialogLoading = false
            })
        },
        downloadPackingSlip () {
            this.downloadAssets({}, 'packingSlip')
        },
        downloadAssets (params, action = 'zip', numberOfLabels = null, packages = []) {
            const orderLines = params.selectedRows || this.selectedRows
            const payload = {
                orderLines: orderLines,
                supplierId: this.supplierId || orderLines[0].supplierProducer.supplierId || orderLines[0].supplierProducer.sourceId || orderLines[0].supplierSourceId,
            }
            let url

            if (action === 'zip') {
                url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.DOWNLOAD_ORDERS
            }
            if (action === 'shippingLabel') {
                url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.DOWNLOAD_SHIPPINGLABEL
                payload.numberOfLabels = numberOfLabels

                if (packages.length) {
                    payload.packages = packages
                }
            }

            if (action === 'packingSlip') {
                url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.DOWNLOAD_PACKINGSLIP
            }

            const jobName = [url, orderLines]
            this.addJob(jobName)
            return this.$api.post(url, payload).then(({ data }) => {
                if (data.data.url) {
                    this.startDownload(data.data.url, jobName)
                } else if (data.data.token) {
                    this.$root.$emit('notification:global', {
                        message: `Download is being prepared. The download will start automatically when ready.`,
                    })
                    this.startDownloadPolling(data.data.token, payload, jobName, action)
                }
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Downloading failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(jobName)
            })
        },
        startDownloadPolling (token, payload, jobName, action) {
            const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.CHECK_TOKEN_RESPONSE.replace('{token}', token)
            this.$store.dispatch('tasks/addTask', {
                title: `Download order line ${action}`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: true,
                onComplete: data => {
                    this.startDownload(data.data.url, jobName)
                },
                onPoll: () => {
                    return this.$api.post(url, payload)
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        startDownload (url, jobName) {
            this.$bus.$emit('DOWNLOAD_URL_RESOLVED', url)
            this.$root.$emit('notification:global', {
                message: `Download started.`,
            })
            this.numberOfShippingLabels = 1
            this.numberOfAttempts = 0
            this.finishJob(jobName)
        },
        bulkUpdateOrderLines (params) {
            const url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.UPDATE_BULK.replace('{supplierId}', this.supplierId)
            const jobName = [url, params]
            this.addJob(jobName)
            this.$api.post(url, params).then(() => {
                this.onActionFinished()
                this.$root.$emit('notification:global', {
                    message: `Orders successfully updated.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Updating orders failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
        filterDateRangeUpdate (value) {
            if (!value || value.dateRangeFrom === null || value.dateRangeTo === null) {
                this.dateRangeFrom = null
                this.dateRangeTo = null

                return
            }
            this.dateRangeFrom = moment(value.startDate)
            this.dateRangeTo = moment(value.endDate)
        },
        handleManualDateInput ($event) {
            this.parsedDateRange = $event.target.value
            $event.target.blur()
            this.$refs.dateRangePicker.open = false
        },
        formatDate (date, allDay) {
            return date.format(allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')
        },
        getOrderLines ({ filterStatusValue }, force = false) {
            let url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.OVERVIEW
                .replace('{orderLineStatus}', filterStatusValue)
                .replace('{supplierId}', this.supplierId)

            if (filterStatusValue === 'all') {
                url = CONFIG.API.ROUTES.ORDERS.ORDERLINES.VIRTUAL_SUPPLIER.OVERVIEW
            }

            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            if (this.dateRangeFrom && this.dateRangeFrom.isValid() && this.dateRangeTo && this.dateRangeTo.isValid()) {
                requestPayload.request.filterModel['dateFrom'] = {
                    filter: this.formatDate(this.dateRangeFrom, true),
                    filterType: 'text',
                    type: 'greaterThan',
                }
                requestPayload.request.filterModel['dateTo'] = {
                    filter: this.formatDate(this.dateRangeTo, true),
                    filterType: 'text',
                    type: 'lessThan',
                }
            }

            if (this.orderLineOverviewType) {
                requestPayload.orderLineOverviewType = this.orderLineOverviewType
            }

            requestPayload.orderOrigin = this.orderOrigin

            if (this.url === url && JSON.stringify(requestPayload) === JSON.stringify(this.requestPayload) && force === false) {
                return
            }
            this.addJob(url)
            this.url = url
            this.requestPayload = requestPayload
            this.$set(this, 'items', null)
            setTimeout(() => {
                if (this.$refs.grid && this.$refs.grid.gridApi) {
                    this.$refs.grid.gridApi.showLoadingOverlay()
                }
            }, 1)

            this.$api.post(this.url, requestPayload).then(({ data }) => {
                this.$set(this, 'items', data.data.items)
            }).catch(() => {

            }).finally(() => {
                this.finishJob(url)
                setTimeout(() => {
                    if (this.$refs.grid && this.$refs.grid.gridApi) {
                        this.$refs.grid.gridApi.hideOverlay()
                    }
                    if (this.items.length === 0 && this.$refs.grid && this.$refs.grid.gridApi) {
                        this.$refs.grid.gridApi.showNoRowsOverlay()
                    }
                }, 1)
            })
            this.options = {
                ...this.baseOptions,
                columns: {
                    ...this.baseOptions.columns,
                },
            }

            Object.entries(this.options.columns).forEach(([key, value]) => {
                if (!value.virtualSupplierField) {
                    return
                }

                this.$set(this.options.columns[key], 'hide', !value.virtualSupplierField.includes(this.orderLineOverviewType))
            })

            this.$set(this.options.columns['Invoiced'], 'hide', !this.isToInvoice)
        },
        dateRangeClear () {

        },
    },
    watch: {
        'filters.search' (value) {
            this.$refs.grid.gridApi.setQuickFilter(value)
        },
        '$route.params.orderLineId' () {
            this.$nextTick(() => {
                this.$refs.grid.gridApi.redrawRows()
            })
        },
        '$route.params.supplierId' () {
            window.location.reload()
        },
    },
    computed: {
        showAlertInfoRegisterDelay () {
            return this.selectedRows.some((item) => item.delayedComplaintId || item.isDelayed)
        },
        isAllowedToUpdateOrderLine () {
            if (this.isSupplier) {
                return false
            }

            if (!this.isAllowedTo('SupplierCentral/updateOrderLine')) {
                return false
            }

            return true
        },

        isUpdateOrderLineButtonEnabled () {
            if (this.loading) {
                return false
            }

            if (this.selectedOrderLines.length === 1) {
                return true
            }

            return false
        },

        maxSelectedRowsAllowed () {
            if (this.isToInvoice) {
                return -1
            }
            return this.maxSelectedRows
        },
        isInternationalShipment () {
            if (this.selectedRows.length === 0) {
                return false
            }

            try {
                const orderLine = this.selectedRows[0]
                const destination = orderLine.destination
                const supplierId = orderLine.supplierProducer.supplierId
                const facilityId = orderLine.supplierProducer.facilityId

                const facility = this.$store.getters['suppliers/getLocationBySupplierIdAndLocationId'](supplierId, facilityId)
                if (facility.country === destination) {
                    return false
                }
            } catch (e) {

            }

            return true
        },
        selectedOrderlinesCurrencyCode () {
            if (this.selectedRows.length === 0) {
                return ''
            }

            for (let orderLine of this.selectedRows) {
                let currencyCode = this.getCurrencyCodeForOrderLine(orderLine)
                if (currencyCode) {
                    return currencyCode
                }
            }

            for (let orderLine of this.items) {
                let currencyCode = this.getCurrencyCodeForOrderLine(orderLine)
                if (currencyCode) {
                    return currencyCode
                }
            }

            return ''
        },
        isAllowedToView () {
            if (!this.filterStatus || !this.filterStatus.value) {
                return false
            }
            return this.isAllowedTo(`SupplierCentral/get${this.$options.filters.upperCaseFirst(this.filterStatus.value)}OrderLinesBySupplierId`)
        },
        filteredItems () {
            if (!this.manualOrderNumberInput) {
                if (this.$refs.grid) {
                    this.$refs.grid.deselectAllRows()
                }
                return this.items
            }

            if (this.items === null) {
                return null
            }
            const entityNumbers = this.manualOrderNumberInput.split(/(?:,| |-|\n)+/).filter(entityNumber => entityNumber.toString().length)

            const filteredItems = this.items.filter(orderLine => {
                return entityNumbers.includes(orderLine.orderLineNumber) ||
                    entityNumbers.includes(orderLine.orderLineId)
            })

            this.$nextTick(() => {
                this.$refs.grid.selectAllVisibleRows()
            })
            return filteredItems
        },
        breadcrumbElement () {
            return {
                name: this.filterStatus.fullTitle,
            }
        },
        filterStatus () {
            if (!this.$route.params.status) {
                return CONFIG.STATUSSES.SUPPLIERS.VIRTUAL_SUPPLIER_ORDERLINES_STATUS
            }

            return CONFIG.STATUSSES.SUPPLIERS.ORDERLINES.find(({ value }) => value === this.$route.params.status)
        },
        showAddShipment () {
            if (!this.isAllowedTo('SupplierCentral/addManualTrackingLink')) {
                return false
            }
            if (this.selectedOrderLines.length === 0) {
                return false
            }
            const firstOrderLine = this.selectedOrderLines[0]
            if (this.selectedOrderLines.every(orderLine => {
                return firstOrderLine.supplierProducer.supplierId === orderLine.supplierProducer.supplierId && ['PRODUCED', 'IN_PRODUCTION'].includes(orderLine.orderLineStatus)
            })) {
                return true
            }

            return this.isProduced || this.isInProduction
        },
        showDownloadShippingLabel () {
            if (!this.isAllowedTo('SupplierCentral/generateShippingLabel')) {
                return false
            }
            return this.isInProduction || this.isProduced
        },
        canDownloadShippingLabel () {
            if (this.selectedOrderLines.length === 0) {
                return false
            }
            return this.selectedOrderLines.every((orderLine, index, selectedOrderLines) => {
                if (orderLine.supplierCarrier.carrierName.startsWith('OWN_')) {
                    return false
                }

                const matchingOrderLine = index === 0 || orderLine.addressHash === selectedOrderLines[index - 1].addressHash
                const matchingCarrierName = index === 0 || orderLine.supplierCarrier.carrierName === selectedOrderLines[index - 1].supplierCarrier.carrierName
                return matchingOrderLine && matchingCarrierName
            })
        },
        isToInvoice () {
            return this.filterStatus && this.filterStatus.name === 'invoicing'
        },
        isApproved () {
            return this.filterStatus && this.filterStatus.name === 'approved'
        },
        isInProduction () {
            return this.filterStatus && this.filterStatus.name === 'inProduction'
        },
        isProduced () {
            return this.filterStatus && this.filterStatus.name === 'produced'
        },
        selectedOrderLines () {
            return this.selectedRows
        },
        totalAmount () {
            return this.selectedRows.flatMap(data => data.productCost).reduce((total, cost) => Number(total) + Number(cost), 0)
        },
        parsedDateRange: {
            get () {
                if (this.dateRangeFrom === null || this.dateRangeTo === null) {
                    return ''
                }
                if (!this.dateRangeFrom.isValid() || !this.dateRangeTo.isValid()) {
                    return ''
                }

                return this.formatDate(this.dateRangeFrom, true) + ' - ' + this.formatDate(this.dateRangeTo, true)
            },
            set (value) {
                if (typeof value !== 'string') {
                    return
                }

                let [start, end] = value.split(' - ')

                start = moment(start)
                end = moment(end)

                if (start.isValid()) {
                    this.dateRangeFrom = start
                }
                if (end.isValid()) {
                    this.dateRangeTo = end
                }
            },
        },
    },

    created () {
        this.$watch('filterStatus.value', function (filterStatusValue, oldValue) {
            if (filterStatusValue !== oldValue) {
                this.$set(this, 'manualOrderNumberInput', null)
                this.$set(this, 'dateRangeFrom', null)
                this.$set(this, 'dateRangeTo', null)
            }

            this.getOrderLines({ filterStatusValue })
        }, {
            immediate: true,
        })
        this.$watch(function () {
            return [this.dateRangeFrom, this.dateRangeTo]
        }, function (newValues, oldValues) {
            if (JSON.stringify(newValues) === JSON.stringify(oldValues)) {
                return
            }

            this.getOrderLines({
                filterStatusValue: this.filterStatus.value,
            })
        }, {
            immediate: true,
        })

        this.$watch('orderLineOverviewType', function () {
            this.getOrderLines({
                filterStatusValue: this.filterStatus.value,
            })
        })
        this.$watch('orderOrigin', function () {
            this.getOrderLines({
                filterStatusValue: this.filterStatus.value,
            })
        })

        const urlSuppliers = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(urlSuppliers)
        this.$api.post(urlSuppliers, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.allSuppliers = data.data.items
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting suppliers failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.loading = false
            this.finishJob(urlSuppliers)
        })

        const urlServiceLevels = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.OVERVIEW_ALL
        this.addJob(urlServiceLevels)
        this.$api.post(urlServiceLevels, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.allServiceLevels = data.data.items
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting service levels failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.loading = false
            this.finishJob(urlServiceLevels)
        })
    },
}
</script>

<style lang="scss" scoped>
    .select-new-status-modal {
        width: 100vw;
        max-width: 400px;
    }
    .download-section {
        display: inline-flex;
        align-items: center;
    }
    .daterangepicker-wrapper {
        width: 240px;
        /deep/ .daterangepicker.opensright {
            top: 50px;
        }
    }
    .shipping-label-number-prompt {
        max-width: 400px;
    }
    .manual-order-numbers {
        max-width: 100%;
        width: 100%;
        &__clear {
            cursor: pointer;
        }
    }
    /deep/ .filters {
        display: block;
    }
    .button-wrapper {
        display: inline-block;
        vertical-align: middle;
    }
    .selected-orders {
        font-size: 12px;
        &__list {
            max-height: 80px;
            overflow: scroll;
            margin: 0;
        }
        &__title {
            font-size: 13px;
            font-weight: normal
        }
    }
    .complaint-costs /deep/ input {
        padding: 0px;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        &:focus-visible {
         outline: none
        }
    }
    .complaint-costs .complaint-info-price {
        color:rgba(0, 0, 0, 0.54);
        font-size:13px;
    }
   .hover-actions /deep/ .ag-pinned-right-cols-container {
        visibility: hidden;
        width: 90px !important;
        height: unset !important;
        max-width: unset !important;
        min-width: unset !important;
        position: absolute;
        right: 0;
        background: white;
        border: none !important
    }
     .hover-actions /deep/ .ag-theme-material .ag-pinned-right-header {
        display: none;
        margin-left: 10px;
    }
     .hover-actions /deep/ .ag-theme-material .ag-cell--actions {
        width: unset !important;
        right: 0;
        border: none !important;
    }
     .hover-actions /deep/ .ag-row-hover .ag-cell-first-right-pinned {
        visibility: visible;
    }
     .hover-actions /deep/ .ag-theme-material .ag-row-hover {
        background: transparent !important;
    }

    /deep/.ag-grid-line-heigth-0{
        line-height: 0px !important;
        padding-right: 10px !important;
        & .ag-grid__cell--multi-line{
            padding-top: 5px;
            padding-bottom: 0px;
        }
    }

    /deep/ .ag-center-cols-container{
        width: 100% !important;
    }
</style>
