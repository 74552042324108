<template>
    <div class="change-attribute">
        <div class="d-flex align-items-end">
            <mercur-select :disabled="isLoading" v-model="attributeToChange" class="mr-2 mb-0">
                <template slot="label">Attribute to change</template>
                <option :value="attribute.attributeName" v-for="attribute in sortedAttributes" :key="attribute.attributeName">{{attribute.attributeName}}: {{attribute.attributeOption}}</option>
            </mercur-select>
            <mercur-button :disabled="!attributeToChange || isLoading" class="btn btn-yellow btn-icon mt-0 mr-2" @click="getAlternativeSkusForAttribute()"><i class="fas fa-search"></i></mercur-button>
        </div>
        <div v-if="alternativeSKUs" class="mt-2">
            <div v-if="alternativeSKUs.length === 0">
                No alternative SKU's found.
            </div>
            <div v-else>
                <table class="table table-sm alternative-skus">
                    <tr>
                        <th>Option</th>
                        <th>Supplier/facility</th>
                        <th>Production days</th>
                        <th>Total costs</th>
                        <th>SKU</th>
                    </tr>
                    <tr v-for="(result, key) in alternativeSKUs" :key="key">
                        <td class="pr-3 alternative-skus__option">
                            <mercur-radio v-model="selectedAlternativeSKU" :value="result" class="mb-0">{{result.attributeOption}}</mercur-radio>
                        </td>
                        <td class="vertical-middle">{{ getSupplierNameById(result.supplierId) }} - {{ getFacilityNameById(result.facilityId) }}</td>
                        <td class="align-center vertical-middle">{{ result.productionDays }}</td>
                        <td class="vertical-middle">
                            <template v-if="getFacilityCurrencyCode(result.facilityId)">
                                {{ result.totalCost | asMoney(getFacilityCurrencyCode(result.facilityId)) }}
                            </template>
                            <template v-else>
                                {{ result.totalCost }}
                            </template>
                        </td>
                        <td class="vertical-middle">{{ result.sku }}</td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <mercur-button
                                class="btn btn-yellow mt-3"
                                :disabled="!isSelectionValid"
                                @click="submitSkuChange"
                            >Continue</mercur-button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="position-relative">
            <mercur-progress-bar indeterminate v-if="isLoading"></mercur-progress-bar>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'UpdateOrderLineSkuDialog',
    props: {
        attributes: {
            type: Array,
        },
        selectedOrderLines: {
            type: Array,
        },
    },
    data () {
        return {
            attributeToChange: null,
            alternativeSKUs: null,
            selectedAlternativeSKU: null,
            isLoading: false,
            locations: {},
            suppliers: {},
        }
    },
    computed: {
        isSelectionValid () {
            if (!this.selectedAlternativeSKU) {
                return false
            }
            if (!this.suppliers[this.selectedAlternativeSKU.supplierId]) {
                return false
            }
            if (!this.locations[this.selectedAlternativeSKU.facilityId]) {
                return false
            }
            return true
        },
        sortedAttributes () {
            const attributes = JSON.parse(JSON.stringify(this.attributes))
            attributes.sort((a, b) => a.attributeName.toString().localeCompare(b.attributeName.toString()))
            return attributes
        },
    },
    methods: {
        getSupplierNameById (supplierId) {
            if (!this.suppliers[supplierId]) {
                return ''
            }
            return this.suppliers[supplierId].supplierName
        },
        getFacilityNameById (facilityId) {
            if (!this.locations[facilityId]) {
                return ''
            }
            return this.locations[facilityId].locationName
        },
        getFacilityCurrencyCode (facilityId) {
            if (!this.locations[facilityId]) {
                return null
            }
            const officeId = this.locations[facilityId].parentId

            if (!this.locations[officeId]) {
                return null
            }
            return this.locations[officeId].financeSettings.currency
        },
        getAlternativeSkusForAttribute () {
            this.$set(this, 'alternativeSKUs', null)
            this.isLoading = true
            const orderLine = this.selectedOrderLines[0]
            const attributeName = this.attributeToChange
            const payload = {
                productHash: orderLine.productHash,
                sku: orderLine.sku,
                attribute: attributeName,
                supplierId: orderLine.producer.supplierId,
                facilityId: orderLine.producer.facilityId,
                includeSupplier: true, // TODO: change after debugging
                quantity: orderLine.quantity,
                countryTo: orderLine.address.countryCode,
            }
            this.$api.post(CONFIG.API.ROUTES.ORDERS.ORDERLINES.LIST_ALTERNATIVE_SKUS, payload).then(({ data }) => {
                this.$set(this, 'alternativeSKUs', data.data)
                const supplierData = this.alternativeSKUs.reduce((supplierIds, result) => {
                    supplierIds[result.supplierId] = {
                        supplierId: result.supplierId,
                        facilityId: result.facilityId,
                    }
                    return supplierIds
                }, {})

                Object.values(supplierData).forEach(({ facilityId, supplierId }) => {
                    this.$store.dispatch('suppliers/fetchSupplierById', supplierId).then(supplier => {
                        this.$set(this.suppliers, supplier.supplierId, supplier)
                    })
                    this.$store.dispatch('suppliers/fetchSupplierLocations', supplierId).then(locations => {
                        locations.forEach(location => {
                            this.$set(this.locations, location.locationId, location)
                        })
                    })
                })
            }).catch(e => {
                if (CONFIG.DEBUG) {
                    console.error(e)
                }
                this.$root.$emit('notification:global', {
                    message: `Getting alternative sku's failed. Please try again.`,
                    type: 'error',
                })
            }).finally(() => {
                this.isLoading = false
            })
        },
        submitSkuChange () {
            const facility = this.locations[this.selectedAlternativeSKU.facilityId]
            const supplier = this.suppliers[this.selectedAlternativeSKU.supplierId]
            facility.facilityName = supplier ? `${supplier.supplierName} - ${facility.locationName}` : facility.locationName
            facility.facilityId = this.selectedAlternativeSKU.facilityId

            this.$emit('setAlternativeSKU', {
                selectedAlternativeSKU: this.selectedAlternativeSKU,
                facility,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.change-attribute {
    padding-top: 10px;
    min-height: 220px;
}
.alternative-skus {
    font-size: 11px;
    line-height: 1.3em;
    margin-bottom: 0;
    margin-top: 10px;
    &__option {
        vertical-align: middle;
    }
}
</style>
