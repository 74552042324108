<template>
    <div>
        <div
            class="filedropper"
            :class="{'filedropper--is-highlighted': highlighted}"
            @dragenter.prevent.stop="highlight"
            @dragover.prevent.stop="highlight"
            @dragleave.prevent.stop="unhighlight"
            @drop="dropped"
            @click="$refs.fileinput.click()"
        >
            <input
                @change="selected"
                type="file"
                name="file"
                multiple
                ref="fileinput"
                class="filedropper__input"
                :accept="acceptedMimeTypes"
                data-test="filedropperUploadField"
            />
            <slot>Drop files or </slot><mercur-button class="btn btn-raised text-uppercase ml-2">{{buttonText}}</mercur-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Filedropper',
    props: {
        buttonText: {
            default: 'Select',
        },
        url: {
            required: true,
        },
        onlyAllowImages: {
            default: false,
        },
        mimeTypes: {},
    },
    data () {
        return {
            highlighted: false,
        }
    },
    computed: {
        acceptedMimeTypes () {
            if (this.mimeTypes) {
                return this.mimeTypes
            }

            if (this.onlyAllowImages) {
                return 'image/*'
            }
            return '*'
        },
    },
    methods: {
        highlight ($event) {
            this.highlighted = true
        },

        unhighlight () {
            this.highlighted = false
        },

        selected ($event) {
            this.handleFilesSelected([...$event.target.files])
        },

        dropped ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            this.handleFilesSelected([...$event.dataTransfer.files])
        },
        handleFilesSelected (files) {
            const currentTimestamp = Number((new Date()).getTime())

            const bulkMap = files.map((file, index) => {
                const fileTimestamp = currentTimestamp + index
                const fileExtension = file.name.split('.').pop()
                return {
                    fileUploadId: this.$uuid.v4(),
                    originalFilename: file.name,
                    objectName: `${fileTimestamp}.${fileExtension}`,
                    file,
                }
            })

            bulkMap.forEach(file => {
                this.$emit('uploadUpdate', {
                    type: 'start',
                    file,
                    percentCompleted: 0,
                })
            })

            this.getUploadLinks(bulkMap.map(file => file.objectName)).then(({ data }) => {
                const fileUploadDetails = data.map(uploadDetail => {
                    let reference = bulkMap.find(mapItem => uploadDetail.objectName === mapItem.objectName) ||
                        bulkMap.find(mapItem => uploadDetail.fileName === mapItem.objectName)

                    return {
                        ...uploadDetail,
                        ...reference,
                        uploadDetail,
                    }
                })
                this.processFiles(fileUploadDetails)
            })

            this.unhighlight()
        },
        getUploadLinks (files) {
            return this.$api.post(this.url, {
                files,
            })
        },

        processFiles (fileUploadDetails) {
            fileUploadDetails.forEach(details => {
                const config = {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        this.$emit('uploadUpdate', {
                            type: 'progress',
                            file: details,
                            percentCompleted,
                        })
                    },
                }
                // Google Cloud Upload:
                axios.put(details.url, details.file, config).then(() => {
                    this.$emit('uploadUpdate', {
                        type: 'completed',
                        file: details,
                        percentCompleted: 100,
                    })
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.finishJob(details.objectName)
                })
            })
        },
    },

}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/_filedropper.scss';
</style>
